import Vue from 'vue'

// import DefaultLayout from '~/layouts/Default.vue'
import App from './App.vue'
import router from './router'

// 全局引入按需引入UI库 vant
import '@/plugins/bootstrap'

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
