<template>
  <div>
    <b-container fluid>
      <b-navbar class="p-0 row bd-navbar px-5" fixed="top" toggleable="lg" type="light">
          <b-navbar-brand class="font-family-headings" to="/">
            <span class="h3 text-dark">橘喵技术</span>
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto ">
              <b-nav-item-dropdown text="产品" right>
                <b-dropdown-item href="#">TPM</b-dropdown-item>
                <b-dropdown-item href="#">TPM SaaS</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item-dropdown text="解决方案" right>
                <b-dropdown-item to="/s/1">消费品行业解决方案
                </b-dropdown-item>
                <b-dropdown-item to="/s/2">促销管理</b-dropdown-item>
                <b-dropdown-item to="/s/3">费用管控</b-dropdown-item>
                <b-dropdown-item to="/s/4">主数据管理</b-dropdown-item>
                <b-dropdown-item to="/s/5">经销商管理</b-dropdown-item>
                <b-dropdown-item to="/s/6">客户管理</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item to="/c/1" exact-active-class="active">行业案例</b-nav-item>
              <b-nav-item-dropdown text="关于我们" right>
                <b-dropdown-item href="#">公司介绍</b-dropdown-item>
                <b-dropdown-item href="#">服务和支持</b-dropdown-item>
                <b-dropdown-item href="#">招贤纳士</b-dropdown-item>
                <b-dropdown-item href="#">联系我们</b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
      </b-navbar>
      <b-row class="align-items-start">
        <transition name="fade" appear>
          <b-col tag="main" cols="12" lg="12" class="bg-white p-0 rounded shadow-lg">
            <div class="overflow-hidden p-3 pt-5 mt-5 ">
              <div class="layout-content">
                <keep-alive v-if="$route.meta.keepAlive">
                  <router-view></router-view>
                </keep-alive>
                <router-view v-else></router-view>
              </div>
            </div>
          </b-col>
        </transition>
        <!-- <site-sidebar/> -->
      </b-row>
    </b-container>
    <site-footer/>
  </div>
</template>

<script>
// import SiteSidebar from '@/components/SiteSidebar.vue'
import SiteFooter from '@/components/SiteFooter.vue'
export default {
  components: {
    // SiteSidebar,
    SiteFooter
  }
}
</script>

<style>
.fade-enter-active {
  transition: .5s;
}
.fade-enter {
  opacity: 0;
}

.bd-navbar {
  background-color: #fbfbfb;
  box-shadow:0 0.25rem 0.25rem rgb(0 0 0 / 25%), inset 0 -1px 5px rgb(0 0 0 / 25%)
}
</style>
