/**
 * 基础路由
 * @type { *[] }
 */
import Layout from '@/layouts'

export const constantRouterMap = [
  // {
  //   path: '/login',
  //   component: () => import('@/views/login'),
  //   hidden: true
  // },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    meta: {
      title: 'Home',
      keepAlive: false
    },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home'),
        meta: { title: '贝斯特', keepAlive: false }
      },
      {
        path: '/s/:id',
        name: 'Solution',
        component: () => import('@/views/solution'),
        meta: { title: '解决方案', keepAlive: false }
      },
      {
        path: '/c/:id',
        name: 'Case',
        component: () => import('@/views/case'),
        meta: { title: '行业案例', keepAlive: false }
      }
      // ,
      // {
      //   path: '/me',
      //   name: 'Me',
      //   component: () => import('@/views/me'),
      //   meta: { title: 'Me', keepAlive: false }
      // },
      // {
      //   path: '/category',
      //   name: 'Category',
      //   component: () => import('@/views/category'),
      //   meta: { title: 'Category', keepAlive: false }
      // },
      // {
      //   path: '/promotion',
      //   name: 'Promotion',
      //   component: () => import('@/views/promotion'),
      //   meta: { title: 'Promotion', keepAlive: false }
      // },
      // {
      //   path: '/promotion/:pkey',
      //   component: () => import('@/views/promotion/edit'),
      //   name: 'EditPromotion',
      //   hidden: true,
      //   meta: {
      //     title: 'Edit Promotion',
      //     icon: 'edit',
      //     breadcrumb: false,
      //     tag: false,
      //     activeMenu: '/promotion'
      //   }
      // },
      // {
      //   path: '/payment',
      //   name: 'Payment',
      //   component: () => import('@/views/payment'),
      //   meta: { title: 'Payment', keepAlive: true }
      // },
      // {
      //   path: '/payment/:pkey',
      //   component: () => import('@/views/payment/edit'),
      //   name: 'EditPayment',
      //   hidden: true,
      //   meta: {
      //     title: 'Edit Payment',
      //     icon: 'edit',
      //     breadcrumb: true,
      //     tag: false,
      //     activeMenu: '/payment'
      //   }
      // }
    ]
  }
]
